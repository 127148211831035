// this file should be kept very lightwieght and include styles for above the fold content.


@media(max-width:768px){.clearfix-xs:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
    }
}
.full-width{
  padding: 0 !important;
  .container{
    padding:0 !important;
    margin: 0 !important;
    max-width: 100vw !important;
    // max-height: 800px;
  }
}
.carousel-wrap{
  position: relative;
}
.welcome-panel{
  position: absolute;
  left: 5em;
  bottom: 8%;
  z-index: 2;
  @media screen and (max-width: 992px){
    bottom: 5%;
  }
  @media screen and (max-width: 725px){
    display: none;
  }
  section.dark .welcome-message p, section.dark .welcome-message h3 {
    color: white !important;
  }
  .welcome-message{
    padding: 1em;
    border-radius: 10px;
    //background-color: rgba($section-alt-bg, 0.75);
    background-color: #F79F84;
    //background-color: rgba(247,159,132,.75);
    margin: 1.25em;
    margin-left: 0;
    p:first-child{
      color: #eee !important;
      margin-bottom: 0;
    }
    p:last-child{
      margin-bottom: 0;
      color: white !important;
    }
    h2, h3 {
      margin-top: 10px;
      color: white !important;
    }
  }
}

.slick-dots{
  position: absolute;
  z-index: 3;
  bottom: 2em;
}
.request-button{
  padding: .4em 2.5em;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: white;
  border: 2px solid $section-dark-bg;
  border-radius: 6px;
  color: $section-dark-bg;
  &:hover{
    background-color: #fafafa;
    cursor: pointer;
  }
  &.light{
    border: 2px solid $section-alt-bg;
    color: $section-alt-bg;
    padding: .75em 1.5em;
  }
}
.breadcrumb{
  background-color: transparent !important;
  position: absolute;
  bottom: 2px;
  left: 6px;
  li:after{
    margin: 0 6px;
    content: ' >';
  }
  li:last-child:after{
    content: '';
  }
}
// slider

.carousel-wrap, .slider-wrap{
  max-height: 600px;
  overflow: hidden;

  @media screen and (min-width: 945px){
    .slider-image{
      transform: translateY(-12%);
    }
  }
  @media screen and (max-width: 1175px ){
    max-height: 545px;
  }
  @media screen and (max-width: 1060px ){
    max-height: 495px;
  }
  @media screen and (max-width: 945px ){
    max-height: 445px;
  }
  @media screen and (max-width: 815px ){
    max-height: 420px;
  }
  @media screen and (max-width: 760px ){
    max-height: 370px;
  }
  @media screen and (max-width: 630px ){
    max-height: 270px;
  }
  @media screen and (max-width: 516px ){
    max-height: 250px;
  }
  @media screen and (max-width: 460px ){
    max-height: 210px;
  }
  @media screen and (max-width: 410px ){
    max-height: 170px;
  }
}
